<template>
  <div>
    <b-button @click="$router.go(-1)" variant="outline-warning" class="btn-icon my-1" size="sm">
      <span class="">RETOUR</span>
    </b-button>
    <div class="">
      <!-- form start -->
      <validation-observer ref="addForm">
        <b-form @submit.prevent>
          <b-card class="mt-1 block-text" title="">
            <div class="border-bottom pb-1 mb-2">
              <span class="h4">EFFECTUER UN VIREMENT</span>
            </div>
            <b-badge
              class="d-flex mb-2"
              style="font-size: 1em"
              variant="secondary"
            >
              <b-icon-exclamation-circle-fill
                scale="2"
                class="mt-1 mx-1"
              ></b-icon-exclamation-circle-fill>
              <span class="d-flex py-1" w-50>
                SOLDE DU COMPTE "{{ data.libelle }}" :
                {{ thousandSeparator(data.solde) + " " + data.devise }}
              </span>

              <b-badge
                variant="light"
                class="text-primary pt-1 badge-virement ml-auto"
              >
                SOLDE APRÈS VIREMENT :
                {{ thousandSeparator(soldeAfterVirement) + " " + data.devise }}
              </b-badge>
            </b-badge>

            <div class="repeater-form">
              <b-row
                v-for="(item, index) in items"
                :key="item.id"
                :id="item.id"
                ref="row"
              >
                <b-form-group class="col">
                  <validation-provider
                    #default="{ errors }"
                    name="caisse_destinataire"
                    rules="required"
                  >
                    <label for="caisse_destinataire"
                      >Caisse destinataire
                      <span class="text-danger">*</span></label
                    >
                    <b-form-select
                      id="caisse_destinataire"
                      v-model="item.caisse_destinataire"
                      :options="caisse_destinataireList"
                      value-field="id"
                      text-field="libelle"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0]
                        ? errors[0]
                        : item.errorCaisse_destinataire
                        ? item.errorCaisse_destinataire
                        : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>

               <!--  <b-form-group class="col">
                  <validation-provider
                    #default="{ errors }"
                    name="solde_initial"
                    rules="required"
                  >
                    <label for="solde_initial">Solde initial</label>
                    <cleave
                      class="form-control"
                      :raw="false"
                      id="solde_initial"
                      type="text"
                      disabled
                      :options="numberFormat"
                      v-model="item.solde_initial"
                      placeholder="Solde initial"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group> -->

                <!-- <b-form-group class="col">
                  <validation-provider
                    #default="{ errors }"
                    name="solde_final"
                    rules="required"
                  >
                    <label for="solde_final">Solde final</label>
                    <cleave
                      class="form-control"
                      :raw="false"
                      id="solde_final"
                      type="text"
                      disabled
                      :options="numberFormat"
                      v-model="item.solde_final"
                      placeholder="Solde final"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
 -->
                <b-form-group class="col">
                  <validation-provider
                    #default="{ errors }"
                    name="motif"
                    rules="required"
                  >
                    <label for="motif"
                      >Motif <span class="text-danger">*</span></label
                    >
                    <b-form-input
                      id="motif"
                      type="text"
                      :disabled="item.caisse_destinataire ? false : true"
                      v-model="item.motif"
                      placeholder="Motif"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col">
                  <validation-provider
                    #default="{ errors }"
                    name="montant"
                    rules="required"
                  >
                    <label for="montant"
                      >Montant <span class="text-danger">*</span></label
                    >
                    <!-- <b-form-input
                      id="montant"
                      type="number"
                      :disabled="item.caisse_destinataire ? false : true"
                      v-model="item.montant"
                      placeholder="Montant"
                      @input="checkAccount(index, 'montant')"
                      :state="errors.length > 0 ? false : null"
                    /> -->

                    <cleave
                      class="form-control"
                      :raw="false"
                      id="montant"
                      type="text"
                      :disabled="item.caisse_destinataire ? false : true"
                      :options="numberFormat"
                      v-model="item.montant"
                      placeholder="Montant"
                      :state="errors.length > 0 ? false : null"
                    />
                    <!-- <small class="text-danger">{{ check_montant[index] ? check_montant[index] : '' }}</small> -->
                    <small class="text-danger">{{
                      errors[0]
                        ? errors[0]
                        : item.errorMontant
                        ? item.errorMontant
                        : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Remove Button -->
                <div class="mb-50 col-1 mr-1" v-if="showRemoveItem > 1">
                  <b-button
                    size="sm"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span></span>
                  </b-button>
                </div>
              </b-row>
            </div>

            <b-button
              size="sm"
              variant="primary"
              @click="repeateAgain"
              :disabled="disabled"
              class="my-1"
            >
              <b-icon-plus></b-icon-plus>
              Ajouter une ligne
            </b-button>
          </b-card>

          <!-- submit button start -->
          <div class="BlockbButton mt-2">
            <div>
              <b-button
                @click="$router.go(-1)"
                type="reset"
                class="mr-2 annuler"
                variant="outline-secondary"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButton"
                type="submit"
                variant="primary"
                @click.prevent="toDoVirement"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </div>
          </div>
          <!-- submit button end -->
        </b-form>
      </validation-observer>
      <!-- form end -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cleave from "vue-cleave-component";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BTable,
  BForm,
  BModal,
  BButton,
  BIconPlus,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BInputGroup,
  BFormCheckbox,
  BFormDatepicker,
  BIconCircleFill,
  BIconBuilding,
  BIconLayers,
  BIconOption,
  BIconFile,
  BIconCheckCircleFill,
  BIconExclamationCircleFill,
  BIconPrinterFill,
  BIconArrowClockwise,
  BIconCalendarEvent,
  BInputGroupAppend,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
} from "bootstrap-vue";

export default {
  components: {
    Cleave,
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BBadge,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BFormDatepicker,
    BIconCircleFill,
    BIconExclamationCircleFill,
    BIconArrowClockwise,
    BIconBuilding,
    BIconLayers,
    BIconOption,
    BIconFile,
    BIconPrinterFill,
    BIconCheckCircleFill,
    BIconCalendarEvent,
    BFormSelectOption,
    BIconFileEarmarkArrowDownFill,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      numberFormat: {
        delimiter: " ",
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      data: [],
      disabled: false,
      caisse_destinataireList: [],
      check_montant: [],
      etatButton: true,
      nextTodoId: 2,
      showRemoveItem: 1,
      currentAccount: [],
      selectedAccounts: [],
      items: [
        {
          caisse_destinataire: "",
          solde_initial: "",
          solde_final: "",
          motif: "",
          montant: "",
          errorCaisse_destinataire: "",
          errorMotif: "",
          errorMontant: "",
        },
      ],
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
    this.getDataVirement();
    this.getCaisseDestinataire();
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },

  computed: {
    soldeAfterVirement() {
      if (this.items) {
        var totalMontants = 0;

        this.items.forEach((element) => {
          totalMontants += element.montant
            ? this.$thousandFormater(element.montant)
            : 0;
        });

        return (
          this.$thousandFormater(this.data.solde) -
          this.$thousandFormater(totalMontants)
        );
      }
    },
  },

  methods: {
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    thousandSeparator(amount) {
      if (
        amount !== "" &&
        amount !== undefined &&
        amount !== 0 &&
        amount !== "0" &&
        amount !== null
      ) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return amount;
      }
    },

    repeateAgain() {
      this.showRemoveItem = this.showRemoveItem + 1;

      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },

    removeItem(index) {
      this.showRemoveItem = this.showRemoveItem - 1;
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      this.manageSelectedAccounts(index);
    },

    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },

    // checkMontant(index) {
    // 	if (this.items[index].errorMontant) {
    // 		this.items[index].errorMontant = ''
    // 	}

    // 	let valueMontant = this.items[index].montant

    // 	if (valueMontant > this.soldeAfterVirement(index)) {
    // 		this.check_montant[index] = 'Solde insuffisant !'
    // 	} else {
    // 		this.check_montant = []
    // 	}
    // },

    getCaisseDestinataire() {
      this.$http.get("/caisse-principale-resources").then((response) => {
        this.caisse_destinataireList = response.data.data;

        this.currentAccount = this.caisse_destinataireList.find(
          ({ id }) => id === this.data.id
        );

        const index = this.caisse_destinataireList.indexOf(this.currentAccount);
        this.caisse_destinataireList.splice(index, 1);
      });
    },

    getDataVirement() {
      this.data = JSON.parse(window.sessionStorage.getItem("virement_data"));
    },

    manageSelectedAccounts(sendedIndex, account = null) {
      let selectedIndex = this.selectedAccounts.find(
        ({ index }) => index === sendedIndex
      );

      if (selectedIndex) {
        selectedIndex.account.alreadySelected = false;
        const index = this.selectedAccounts.indexOf(selectedIndex.account);
        this.selectedAccounts.splice(index, 1);
      }

      if (account) {
        account.alreadySelected = true;

        this.selectedAccounts.push({
          index: sendedIndex,
          account: account,
        });
      }

      if (this.selectedAccounts.length == this.caisse_destinataireList.length) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },

    checkAccount(index, value) {
      switch (value) {
        case "dest":
          if (this.items[index].caisse_destinataire) {
            let selectedAccount = this.caisse_destinataireList.find(
              ({ id }) => id === this.items[index].caisse_destinataire
            );

            this.manageSelectedAccounts(index, selectedAccount);

            this.items[index].solde_initial = selectedAccount.solde;
            this.items[index].solde_final = selectedAccount.solde;
          } else {
            this.items[index].solde_initial = 0;
            this.items[index].solde_final = 0;
          }
          break;

        case "montant":
          let montant = this.$thousandFormater(this.items[index].montant);
          let soldeInitial = this.$thousandFormater(
            this.items[index].solde_initial
          );

          this.items[index].solde_final = soldeInitial + montant;
          break;

        default:
          break;
      }
    },

    formatAmounts(items) {
      items = JSON.parse(JSON.stringify(items));

      items.forEach((element) => {
        element.solde_initial = this.$thousandFormater(element.solde_initial);
        element.solde_final = this.$thousandFormater(element.solde_final);
        element.montant = this.$thousandFormater(element.montant);
      });

      return items;
    },

    toDoVirement(item) {
      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Vous êtes sur le point d'effectuer un virement. Voulez-vous continuer ?",
          {
            title: "ATTENTION",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];

            if (!item.caisse_destinataire || !item.montant) {
              value = false;
              i = this.items.length;
              this.showToast(
                "Veuillez remplir tous les champs obligatoires !",
                "danger"
              );
            } else {
              value = true;
            }
          }

          if (value === true) {
            let items = this.formatAmounts(this.items);

            let item = {
              sender: this.currentAccount.id,
              recipients: "",
            };

            item.recipients = items;

            this.etatButton = false;
            this.$http
              .post("/caisse-principale-virement", item)
              .then((result) => {
                if (result.data.success) {
                  this.etatButton = true;
                  this.showToast("Virement effectuée avec succès", "success");
                  this.$router.go(-1);
                } else {
                  this.etatButton = true;
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.etatButton = true;
                this.showToast(err.response, "danger");
              });
          }
        });
    },
  },
};
</script>

<style>
.block-text .card-body {
  padding-bottom: 0px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

@media screen and (max-width: 1000px) {
  .badge-virement {
    margin-left: 1% !important;
  }
}
</style>
